export default function Confirm () {

  return (
    <>
  
<header className="dark-mode header navbar navbar-dark navbar-expand-lg navbar-sticky py-3">
<div className="container px-3">
  <a href="/" className="navbar-brand"><img src="main-logo.png" style={{height: '60px'}} alt="logo" />
</a>
  <div id="navbarNav" className="offcanvas offcanvas-end">
    <div className="offcanvas-header border-bottom">
      <h5 className="offcanvas-title"><img src="main-logo.png" style={{height: '30px'}} alt="logo" /></h5>
      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
    </div>
    <div className="offcanvas-body">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a href="/about" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>ABOUT</a>
        </li>
        <li className="nav-item">
          <a href="/team" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>TEAM</a>
        </li>
        <li className="nav-item">
          <a href="/apply" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>APPLY</a>
        </li>
      </ul>
    </div>    

  </div>
  <div className="cond-flex mb-4 mb-sm-0 nav-mobile">
            <a href="https://twitter.com/Junguruverse" target="_blank" className="btn btn-icon btn-twitter rounded-circle">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="https://www.instagram.com/junguruverse/" target="_blank" className="btn btn-icon  btn-instagram rounded-circle">
              <i  className="bx bxl-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@junguruverse" target="_blank" className="btn btn-icon btn-tiktok rounded-circle">
              <i className="bx bxl-tiktok"></i>
            </a>
  </div>

  <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon" />
  </button>
</div>
</header>
      <div className="App-header-About">
          <p className="align-items-center header-text-about"><b>APPLICATION CONFIRMED!</b></p>
          <p className="align-items-center header-text-about">Your application to JUNGURU has been confirmed</p>
          <a href="/" className="welcome btn btn-lg btn-outline-secondary">Go Home</a>
      </div>
  </>
  );
}
