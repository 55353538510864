import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Index from './Components/Index';
import About from './Components/About';
import Apply from './Components/Apply';
import Team from './Components/Team';
import Confirm from './Components/Confirm';
import './App.css';

function App() {
  return (
    <BrowserRouter>
        <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/team" element={<Team />} />
        <Route path="/confirm" element={<Confirm />} />
          <Route path="/" element={<Index />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        </BrowserRouter>
  );
}
export default App;