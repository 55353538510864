export default function Apply () {

  return (
    <>
  
<header className="dark-mode header navbar navbar-dark navbar-expand-lg navbar-sticky py-3">
<div className="container px-3">
  <a href="/" className="navbar-brand"><img src="main-logo.png" style={{height: '60px'}} alt="logo" />
</a>
  <div id="navbarNav" className="offcanvas offcanvas-end">
    <div className="offcanvas-header border-bottom">
      <h5 className="offcanvas-title"><img src="main-logo.png" style={{height: '30px'}} alt="logo" /></h5>
      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
    </div>
    <div className="offcanvas-body">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a href="/about" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>ABOUT</a>
        </li>
        <li className="nav-item">
          <a href="/team" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>TEAM</a>
        </li>
        <li className="nav-item">
        <a href="/apply" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>APPLY</a>
        </li>
      </ul>
    </div>    

  </div>
  <div className="cond-flex mb-4 mb-sm-0 nav-mobile">
            <a href="https://twitter.com/Junguruverse" target="_blank" className="btn btn-icon btn-twitter rounded-circle">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="https://www.instagram.com/junguruverse/" target="_blank" className="btn btn-icon  btn-instagram rounded-circle">
              <i  className="bx bxl-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@junguruverse" target="_blank" className="btn btn-icon btn-tiktok rounded-circle">
              <i className="bx bxl-tiktok"></i>
            </a>
  </div>

  <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon" />
  </button>
</div>
</header>
      <div className="App-header-About">
          {/* Begin Mailchimp Signup Form */}
    <link
      href="//cdn-images.mailchimp.com/embedcode/classic-071822.css"
      rel="stylesheet"
      type="text/css"
    />
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html:
          "\n\t#mc_embed_signup{background:#fff; clear:left; font-size:36px;  width:00px;}\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n"
      }}
    />
    <div id="mc_embed_signup" className="w-75" style={{backgroundColor:"black"}}>
      <form
        action="https://sweetshopmedia.us16.list-manage.com/subscribe/post?u=7bf2a3fc62c490e0768c51b0a&id=c86ff89d5b&f_id=00c376e0f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate=""
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL"  className="h2"  style={{color:"white"}}>
              Email
            </label>
            <input
              type="email"
              defaultValue=""
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              required=""
            />
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-FNAME" className="h2"  style={{color:"white"}}>Name</label>
            <input
              type="text"
              defaultValue=""
              name="FNAME"
              className="required name"
              id="mce-FNAME"
            />
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            />
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            />
          </div>{" "}
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_7bf2a3fc62c490e0768c51b0a_c86ff89d5b"
              tabIndex={-1}
              defaultValue=""
            />

          </div>
          <input style={{width:"85%"}} className="h2  welcome btn btn-lg btn-outline-secondary" type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe"/>
        </div>
      </form>

    </div>
      </div>
  </>
  );
}
