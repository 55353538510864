export default function Team () {

  return (
    <>
  
<header className="dark-mode header navbar navbar-dark navbar-expand-lg navbar-sticky py-3">
<div className="container px-3">
  <a href="/" className="navbar-brand"><img src="main-logo.png" style={{height: '60px'}} alt="logo" />
</a>
  <div id="navbarNav" className="offcanvas offcanvas-end">
    <div className="offcanvas-header border-bottom">
      <h5 className="offcanvas-title"><img src="main-logo.png" style={{height: '30px'}} alt="logo" /></h5>
      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
    </div>
    <div className="offcanvas-body">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a href="/about" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>ABOUT</a>
        </li>
        <li className="nav-item">
          <a href="/team" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>TEAM</a>
        </li>
        <li className="nav-item">
          <a href="/apply" className="nav-link btn  btn-sm fs-sm rounded d-lg-inline-flex" style={{color: 'white', marginRight: '20px'}}>APPLY</a>
        </li>
      </ul>
    </div>    

  </div>
  <div className="cond-flex mb-4 mb-sm-0 nav-mobile">
            <a href="https://twitter.com/Junguruverse" target="_blank" className="btn btn-icon btn-twitter rounded-circle">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="https://www.instagram.com/junguruverse/" target="_blank" className="btn btn-icon  btn-instagram rounded-circle">
              <i  className="bx bxl-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@junguruverse" target="_blank" className="btn btn-icon btn-tiktok rounded-circle">
              <i className="bx bxl-tiktok"></i>
            </a>
  </div>

  <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon" />
  </button>
</div>
</header>
<section className="container py-3 my-md-3 my-lg-5 dark-mode">
        <h1 className="h1 text-center pt-1 pb-3 mb-3 mb-lg-4">Team</h1>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4">
          {/* Item */}
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Goose_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Angus_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Digital marketing entrepreneur with a tech and influencer background. Clients include Lego, Universal, Sony, Napster, Extensive black book of talent, celebs and industry contacts.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/angusimlach/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/AngusImlach" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                    <a href="https://www.instagram.com/angus_imlach/?hl=en" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">ANGUS IMLACH</h3>
                <p className="fs-sm mb-0">Founder AND CEO</p>
              </div>
            </div>
          </div>
          {/* Item */}
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="morley_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="main-logo.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Seasoned music and brand entrepreneur, 15 years of marketing experience, PR and brand and culture strategy. Clients include Lego, Universal, and Sony</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/morleydave/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/morleydave" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">MORLEY DAVE</h3>
                <p className="fs-sm mb-0">FOUNDER AND CREATIVE DIRECTOR</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Izzy_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Izzy_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Experienced marketeer working on digital strategy for the likes of ellesse, Nokia, Napster, LEGO and more. Background in Sport.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/izzy-imlach-a791b6136/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/Izzy_Imlach" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">IZZY IMLACH</h3>
                <p className="fs-sm mb-0">MARKETING</p>
              </div>
            </div>
          </div>


          {/* Item */}
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="FREEGAS_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="FREEGAS_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Technical Product Manager and leading Web3.0 strategist. Experienced in dapp development. CEO of NiftyLabs. NFT investor and several launches.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/jonahkitay/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/0xFREEGAS" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">JONAH KITAY</h3>
                <p className="fs-sm mb-0">CTO</p>
              </div>
            </div>
          </div>
         {/* Item */}
         <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="needelman_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="needelman_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Solidity and Web3 developer focusing on smart contracts, dapps and websites. CTO of NiftyLabs. NFT investor and several launches.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/alex-needelman-5b2570101/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/Needelman_" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">ALEX NEEDELMAN</h3>
                <p className="fs-sm mb-0">DEVELOPMENT</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Theo_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Theo Blackledge NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">World renowned digital artist with over 300k followers</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.instagram.com/galyosef/" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">THEO BLACKLEDGE</h3>
                <p className="fs-sm mb-0">3D ART LEAD</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Marco_B&W.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                        <img src="Marco_NFT.png" className="rounded-3 nft-logo"  alt="NFT_Logo" />
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">An established digital illustrator with a passion for comic culture. 100k following.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.instagram.com/mastermindsconnect/" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">MARCO THE ARTIST</h3>
                <p className="fs-sm mb-0">ILLUSTRATION DIRECTOR</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="1.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Co-founder of Playtime Coaches Ltd. Founder of the Liam Kelleher Tennis Academy. 15 years experience of coaching tennis to all ages.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.instagram.com/lktacademy/" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">LIAM KELLEHER</h3>
                <p className="fs-sm mb-0">PARTNERSHIPS DIRECTOR</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="2.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Founder and MD of Playtime Coaches Ltd. Franchise Liaison Director - Teddy Tennis Ltd 12 years industry experience -  childrens sport.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://playtimecoaches.com/coaches/" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxs-home" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">JOSH WHITEMAN</h3>
                <p className="fs-sm mb-0">OPERATIONS DIRECTOR</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="3.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Co-founder of Playtime Coaches Ltd. 10 years of experience in the sports coaching industry.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href=" https://www.linkedin.com/in/whitemanjosh/?originalSubdomain=uk" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">JOSH DAWSON</h3>
                <p className="fs-sm mb-0">EVENTS DIRECTOR</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="4.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                        <div className="card-body text-center p-3">
                          <h3 className="fs-sm fw-semibold pt-1 mb-2">Co-founder and MD of Sporty Scholars Ltd.  Nick’s team delivers sport and activity camps to thousands of children.</h3>
                    </div>
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.sportyscholars.com/about " target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxs-home" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">NICK RAPER</h3>
                <p className="fs-sm mb-0">COACHING DIRECTOR</p>
              </div>
            </div>
          </div>


        </div>
      </section>

      <section className="container my-md-3 my-lg-5 dark-mode">
        <h2 className="h1 text-center pt-1 pb-3 mb-3 mb-lg-4">Advisors</h2>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4">
    
        <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="CHRIS DEERING .jpg" style={{width:"100%"}} className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/cdeering/?originalSubdomain=uk" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://en.wikipedia.org/wiki/Chris_Deering" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">CHRIS DEERING</h3>
                <p className="fs-sm mb-0">Ex President, Playstation</p>
              </div>
            </div>
          </div>

          
          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="Ivor.png"  style={{maxHeight:"415px"}} className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/ivor-heller-2b999b44/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://www.afcwimbledon.co.uk/news/2021/august/this-is-the-greatest-moment-in-the-entire-history-of-our-club/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">IVOR HELLER</h3>
                <p className="fs-sm mb-0">C.D - AFC Wimbledon</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="RICHARD  POWAZYNSKI.png" style={{width:"100%", height:"100%"}} className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/richardpowazynski/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://woodiesofficial.com/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">RICHARD POWAZYNSKI</h3>
                <p className="fs-sm mb-0">CEO, WoodiesNFT</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="GARETH INGHAM.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/garethingham/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://urfeed.xyz/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">GARETH INGHAM</h3>
                <p className="fs-sm mb-0">CEO, urFeed</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="SVEN SANDAHL.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/in/sven-sandahl-2264015/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">SVEN SAANDAHL</h3>
                <p className="fs-sm mb-0">Serial Entrepreneur/Philanthropis</p>
              </div>
            </div>
          </div>
          
        
        </div>
      </section>

      <section className="container my-md-3 my-lg-5 dark-mode">
        <h2 className="h1 text-center pt-1 pb-3 mb-3 mb-lg-4">Partners</h2>
      
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4">
      
        <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="sweet.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://twitter.com/SweetshopMedia" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>                    
                    <a href="https://www.sweetshopmedia.com/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">SWEETSHOP</h3>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="nifty-white.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">
                    <a href="https://www.linkedin.com/company/80100415" target="_blank" className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
                      <i className="bx bxl-linkedin" />
                    </a>
                    <a href="https://twitter.com/niftylabs" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>                    
                    <a href="https://niftylabs.dev/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">NIFTYLABS</h3>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="mastermind.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">

                    <a href="https://twitter.com/marcobernardart" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>   
                    <a href="https://www.youtube.com/channel/UCABNElqmEdN-6USQ2bwBjQA" target="_blank" className="btn btn-icon btn-secondary btn-youtube btn-sm bg-white me-2">
                      <i className="bx bxl-youtube" />
                    </a> 
                    <a href="https://www.instagram.com/mastermindsconnect/" target="_blank" className="btn btn-icon btn-secondary btn-instagram btn-sm bg-white me-2">
                      <i className="bx bxl-instagram" />
                    </a>   
                    <a href="https://mastermindsconnect.com/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">MASTERMINDS CONNECT</h3>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card card-hover border-0 bg-transparent">
              <div className="position-relative">
                <img src="TH3M.png" className="rounded-3"  />
                <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
                  <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3" />
                  <div className="position-relative d-flex zindex-2">
                  </div>
                  <div className="position-relative d-flex zindex-2">

                    <a href="https://twitter.com/th3mdotcom" target="_blank" className="btn btn-icon btn-secondary btn-twitter btn-sm bg-white me-2">
                      <i className="bx bxl-twitter" />
                    </a>     
                    <a href="https://th3m.com/" target="_blank" className="btn btn-icon btn-secondary btn-home btn-sm bg-white me-2">
                      <i className='bx bxs-home'></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body text-center p-3">
                <h3 className="fs-lg fw-semibold pt-1 mb-2">TH3M</h3>
              </div>
            </div>
          </div>

        </div>

      </section>




  </>
  );
}
